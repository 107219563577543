<template>
	<Template
			v-bind="{
				...params,
				isLoading
			}"
			@onSubmit="() => onSend()"
			@goTo="path => $router.push(path)"
	/>
</template>
<script>
import Template from '../components/templates/ResetPassword/ResetPassword';
import {c} from '@/components/constants.js'
import {Settings} from "../../settings";

export default {
	components: {
		Template,
	},
	methods: {
		async onSend() {
			this.params.inputs.email.error = null
			const forms = this.params.inputs;
			console.log(forms, 'forms ====')
			if (!forms.email?.value) {
				this.params.inputs.email.error = 'Please insert email!'
				return false;
			}

			try {
				this.isLoading = true;
				const {triggerPasswordReset} = await this.actions.user.triggerPasswordReset({
					email: forms.email?.value?.toLowerCase(),
					domain: Settings.mainDomain
				});
				console.log(triggerPasswordReset)
				if (triggerPasswordReset) {
					this.$router.push('/reset-password-email-sent?email=' + forms.email?.value)
					this.actions.alert.showSuccess({message: 'Email sent successfully!'})
				} else {
					this.actions.alert.showError({message: 'The email doesn\'t exist.'})
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.isLoading = false;
			}
		}
	},
	data() {
		return {
			params: c.resetPassword,
			isLoading: false,
		}
	},
}
</script>
